<template>
  <div class="container-users">
    <modal-header>
      Add new users
      <span class="limit-count" :class="`${limitExceeded > -1 ? 'exceeded' : ''}`"
        >({{ areaUsersNo }} / {{ areaLimit }})</span
      >
    </modal-header>

    <div v-if="validEmails" class="emails-list">
      <div v-for="email in validEmails" :key="email" class="item">
        <div class="email-wrapper">
          <span class="email">{{ email }}</span>
        </div>
        <span class="remove" :id="email" @click="removeEmailFromList">
          <p class="remove-x"></p>
        </span>
      </div>
    </div>

    <div class="emails-list-inputs" v-if="limitExceeded < 0">
      <TextInput
        inset
        multiline
        focusOnMounted
        placeholder="Add user email(s) separated by commas or spaces"
        v-model="emailInput"
        @onInput="onEmailInput"
      />
    </div>
    <div v-else class="exceeded-area-limit">
      You have exceeded the user limit for this Space.<br/> <br />
      <span
        >Please contact <a href="https://spacein.co.uk" target="_blank">SPACEIN team</a> to upgrade your Space and add
        more users.</span
      >
    </div>

    <FooterButtons>
      <btn colour="ghost" @onClick="cancel">
        <template #text>Cancel</template>
      </btn>
      <btn colour="green" @onClick="addAllEmails" :disabled="!allowAddUsers">
        <template #text>Save</template>
      </btn>
    </FooterButtons>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import { TextInput, FooterButtons } from '@/framework';
import ModalHeader from '@/framework/modals/components/newModalHeader.vue';
import EmailListMixin from './EmailListMixin.js'

export default {
  name: 'InviteUsersModal',
  components: { ModalHeader, TextInput, FooterButtons },
  mixins: [ EmailListMixin ],
  props: {
    resetData: Function,
    areaUsersNo: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters({
      areaLimit: 'getCurrentAreaUserLimit',
      areaUsers: 'getUsers'
    }),
    limitExceeded() {
      //allow to add over area limit (let api to handle adding up to limit)
      return this.areaUsersNo - this.areaLimit;
    },
    allowAddUsers() {
      return this.validEmails.length || this.emailInput !== '';
    }
  },
  methods: {
    ...mapActions([
      'handleInviteUsersToSpaceByEmail',
      'modal/close'
    ]),
    async sendEmails(emails) {
      await this.handleInviteUsersToSpaceByEmail({ emails });
      this.resetData();
    },
    cancel() {
      this['modal/close']()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/scss/components/modals/invite_users_modal.scss';
</style>
