<template>
  <div class="manage-users">
    <div class="manage-users-heading">
      <h1 class="manage-users-heading__title">Manage Users</h1>

      <div class="manage-users-heading-buttons">
        <button
          v-if="!selectMode"
          class="manage-users-button manage-users-facets"
          @click="showFacets = !showFacets"
          v-click-outside="() => showFacets = false"
        >
          {{selectedFacet.name}} ({{selectedFacet.count}})
          <SvgIcon name="dropdown-arrow" :style="{transform: showFacets ? 'rotate(180deg)' : null}" />
          <div v-if="showFacets" class="manage-users-facets-dropdown">
            <div
              class="manage-users-facets-dropdown-item"
              :class="{'manage-users-facets-dropdown-item--selected':selectedFacet.name === f.name}"
              v-for="f in facets"
              :key="f.name"
              @click.stop="changeFilter(f)"
            >
              <SvgIcon name="dropdown-tick"/>
              {{f.name}} <span>({{f.count}})</span>
            </div>
          </div>
        </button>

        <button
          v-if="selectMode"
          :class="suffix('manage-users-button', ['-remove'])"
          @click="batchDelete"
        >
          Remove from this Space
        </button>

        <button
          :class="suffix('manage-users-button', [selectMode ? '-select' : ''])"
          @click="toggleSelectMode"
        >
          {{selectMode ? 'Cancel' : 'Select'}}
        </button>
        <!-- <button @click="exportUsersCSV">Export</button> -->
        <generic-menu class="manage-users__options" :items="optionsMenu" />
      </div>
    </div>

    <div class="manage-users-content">
      <div class="manage-users__cta-group">
        <div class="manage-users-invite" @click="inviteUsers">
          <img class="manage-users-invite__icon" src="@/assets/settings-icons/invite-user-white.svg"/>
          Invite new user(s)
        </div>


        <BespokeInput
          class="manage-users__search"
          v-model="search"

          :iconClick="search || searchFocus ? (() => search = '') : null"
          :icon="require( search || searchFocus ? '@/assets/global-icons/cross-white-circle.svg' : '@/assets/search-icons/search.svg' )"

          @focus="searchFocus = true"
          @blur="searchFocus = false"
        >
          <span class="text-italic"><b>Search</b> <span class="text-mid-grey">Users</span></span>
        </BespokeInput>
      </div>

      <div class="manage-users-table-row-heading manage-users-table-row">
        <div class="manage-users-table-row-content">
          <div
            v-for="(name, key) in headings"
            :key="key"
            @click="headingClick(key)"
            :class="[
              'manage-users-table-cell',
              'manage-users-table-cell-'+key,
              'manage-users-table-cell-heading',
              {'manage-users-table-cell-heading--selected': sortBy === key},
            ]"
          >
            {{name}}
            <img
              v-if="sortBy === key"
              class="manage-users-table-heading-arrow"
              src="@/assets/settings-icons/descending-arrow.svg"
              :style="{transform: sortDescending ? null : 'rotate(180deg)'}"
            />
          </div>
        </div>
      </div>

      <div ref="table" class="manage-users-table" @scroll="handleScroll">
        <div
          :class="suffix('manage-users-table-row', [
            selected.includes(user.userId) ? '-selected' : ''
          ])"
          v-for="user in users"
          :key="user.id"
          @click="rowClick(user)"
        >
          <!-- Desktop view, everything is laid out in a row -->
          <div v-if="isDesktop" class="manage-users-table-row-content">
            <div
              v-for="(name, key) in headings"
              :key="key"
              :class="suffix('manage-users-table-cell', [
                ...suffix('-'+key, [
                  key === 'isAdmin' ? '-'+getUserType(user).toLowerCase() : ''
                ])
              ])"
            >
              <!-- todo: when media service is implemented, get user icon from user id -->
              <UserIcon v-if="key === 'icon'" class="manage-users-table-cell__icon" :userId="user.userId || ' '"/>
              <template v-else>{{computeCellText(user, key)}}</template>
            </div>
          </div>
          <!-- Mobile view, everything is stacked -->
          <div v-else class="manage-users-table-row-content">
            <div class="manage-users-table-cell__imageColumn">
              <UserIcon class="manage-users-table-cell__icon" :userId="user.userId || ''"/>
            </div>
            <div class="manage-users-table-cell__textColumn">
              <div class="manage-users-table-cell--fullName">{{user.firstName}} {{user.lastName}} <span class="pronouns">{{user.pronouns ? `(${user.pronouns})` : ''}}</span></div>
              <div class="manage-users-table-cell--email">{{user.email || '-'}}</div>
              <div class="manage-users-table-cell--isAdmin" :class="[suffix('manage-users-table-cell--isAdmin', ['-' + getUserType(user).toLowerCase()])]">{{getUserType(user)}}</div>
            </div>
          </div>
        </div>

        <template v-if="requestNonce">
          <Skeleton
            v-for="i in 3" :key="i"
            size="100% auto" margin="0 0 10px 0" rounded-full shimmer
          >
            <Skeleton size="34px" margin="3px" circle/>
          </Skeleton>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import UserIcon from '../components/UserIcon.vue';
import Skeleton from '../components/global/loading/Skeleton.vue';
import BespokeInput from '@/components/global/inputs/BespokeInput.vue';

import ManageUserModal from '../components/modals/admin/ManageUser.vue';
import InviteUsersModal from '../components/modals/admin/InviteUsersModal.vue';
import GenericMenu from '@/components/menu/GenericMenu.vue'

import SvgIcon from '@/components/global/SvgIcon.vue';

import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'ManageUsers',
  components: { BespokeInput, Skeleton, UserIcon, GenericMenu, SvgIcon },
  data: () => ({
    headings: {
      icon: '',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      usedAt: 'Date Joined',
      isAdmin: 'User Type',
    },
    search: '',
    sortBy: 'email',
    sortDescending: false,
    totalUsers: 0,
    page: 1,
    pageSize: 20,
    selectedFacet: {name: 'All', count: 0},
    showFacets: false,
    facets: [],
    users: [],
    selected: [],
    requestNonce: null,
    lastPage: false,
    searchTimeout: 0,
    searchFocus: false,
    selectMode: false,
  }),
  watch: {
    search(){
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.resetData.bind(this), 500)
    }
  },
  computed: {
    ...mapGetters('viewport', ['isDesktop']),
    ...mapGetters(['getCurrentAreaId', 'getCurrentAreaTitle']),
    optionsMenu() {
      return [
        {
          click: () => this.exportUsersCSV(),
          icon: 'download',
          show: true,
          text: 'Export users data (.csv)'
        }
      ]
    },
  },
  methods: {
    ...mapActions([
      'api/nav/adminGetUsers',
      'api/nav/adminRemoveUsersFromSpace',
      'popups/show/error/custom',
      'popups/show/error',
      'modal/open',
      'analytics/removeUserFromSpace'
    ]),
    inviteUsers(){
      this['modal/open']([InviteUsersModal, {resetData: this.resetData, areaUsersNo: this.totalUsers}])
    },
    async fetchUsers(force = false){
      if(!this.getCurrentAreaId) return // error handling if enter area fails
      if(!force && this.requestNonce) return

      clearTimeout(this.searchTimeout)

      if(force) this.$refs.table.scrollTop = 0

      let nonce = this.requestNonce = {}

      let res = await this['api/nav/adminGetUsers']([this.search, this.sortBy, this.sortDescending, this.page, this.pageSize, this.selectedFacet.name])
      if(!res?.success) this['popups/show/error/custom']('fetching users')

      let data = res.success

      if(!data || nonce !== this.requestNonce) return

      if(this.page === 1) this.users = data.results
      else this.users = [...this.users, ...data.results]

      if(data.pagination.numberOfPages <= this.page) this.lastPage = true
      else this.lastPage = false

      if(this.selectedFacet.name === 'All') {
        this.facets = data.facets
        // assumes the first item has name "All"
        this.selectedFacet = this.facets[0]
      }

      this.requestNonce = null

      this.totalUsers = data.pagination.totalCount

      await this.$nextTick()
      this.handleScroll()
    },
    resetData(){
      this.page = 1
      this.users = []
      this.selected = []
      this.fetchUsers(true)
    },
    changeFilter(facet){
      if(this.selectedFacet.name === facet.name) return
      this.selectedFacet = facet
      this.resetData()
    },
    headingClick(key){
      if(key === 'icon') return

      if(key === this.sortBy) this.sortDescending = !this.sortDescending
      else {
        this.sortBy = key
        this.sortDescending = false
      }

      this.resetData()
    },
    handleScroll(){
      let table = this.$refs.table
      let scrollAmount = table.scrollTop + table.offsetHeight

      if((table.scrollHeight - 200) <= scrollAmount && !this.requestNonce && !this.lastPage) {
        this.page++
        this.fetchUsers()
      }
    },
    rowClick(user){
      if(this.selectMode){
        if(user.status !== 'joined') return this['popups/show/error']({timeout: 10000,
          message: "Only joined users can be selected.<br><br>To cancel an invite, you need to exit Select Mode, click the user whose invite you wish to cancel, and click \"Cancel Invite\""
        })
        else if(this.selected.includes(user.userId)) this.selected.splice(this.selected.indexOf(user.userId), 1)
        else this.selected.push(user.userId)
      }
      else this['modal/open']([ManageUserModal, {user, resetData: this.resetData.bind(this)}])
    },
    toggleSelectMode(){
      if(this.selectMode) this.selected = []
      this.selectMode = !this.selectMode
    },
    suffix(string, array){
      return [
        string,
        ...array.filter(x => x).map(x => string+'-'+x)
      ]
    },
    getUserType(user){
      if(user.status === 'expired') return 'Expired'
      if(user.status === 'cancelled') return 'Cancelled'
      if(user.status === 'rejected') return 'Rejected'
      if(user.status !== 'joined') return 'Pending'
      if(user.isAdmin) return 'Admin'
      return 'User'
    },
    computeCellText(user, key){
      if(key === 'isAdmin') return this.getUserType(user)
      else if(key === 'usedAt'){
        if(!user[key]) return '-'
        return new Date(user[key]).toLocaleTimeString('en-GB', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }).replaceAll(',', '')
      }
      else return user[key] || '-'
    },
    async batchDelete(){
      if(!this.selected.length) return this['popups/show/error']({message: 'No users selected'})

      let selectedUsers = this.users.filter(u => this.selected.includes(u.userId))
      selectedUsers.forEach((u) => {
        this['analytics/removeUserFromSpace'](u)
      })

      let res = await this['api/nav/adminRemoveUsersFromSpace'](this.selected)
      if(!res?.success) this['popups/show/error/custom']('removing users')
      this.resetData()
      this.selectMode = false
    },
    async exportUsersCSV() {
      const rows = []
      const params = ['firstName', 'lastName', 'email', 'isAdmin', 'invitedAt', 'expireAt', 'usedAt', 'cancelledAt', 'status']
      rows.push(params)
      this.users.forEach(u => {
        let row = []
        params.forEach(p => {
          row.push(u[p] || '')
        })
        rows.push(row)
      })

      let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n")

      var encodedUri = encodeURI(csvContent)
      // window.open(encodedUri)
      var link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${this.getCurrentAreaTitle} Users.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  mounted(){
    this.fetchUsers(true)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/scss/pages/manage_users.scss';
</style>
