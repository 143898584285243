<template>
	<div class="manage-user">
		<UserIcon v-if="joined" class="manage-user__icon" :userId="user.userId" userStatus="dark"/>

		<div class="manage-user__main">
			<div v-if="name" class="manage-user__name">{{name}} <span class="pronouns">{{pronouns}}</span></div>
			<div class="manage-user__email">{{user.email}}</div>
		</div>

		<!-- this will never render since these fields aren't implemented in the back-end yet -->
		<div v-if="user.field1 || user.field2" class="manage-user__fields">
			<div v-if="user.field1">{{user.field1}}</div>
			<div v-if="user.field1">{{user.field2}}</div>
		</div>

		<div v-if="joined" class="manage-user__date">Joined: {{date}}</div>

		<TextInput
			class="manage-user__inviteURL"
			v-if="pending"
			readonly
			inset
			:icon="require('@/assets/global-icons/copy.svg')"
			copyOnClick
			label="Click to copy invite link"
			v-model="inviteURL"
		/>

		<div v-if="joined" class="manage-user__toggleAdmin">
			<button
				class="user"
				@click="toggleAdmin(false)"
				:class="{selected: !user.isAdmin && !isBusy}"
			>
				User
			</button>

			<button
				class="admin"
				@click="toggleAdmin(true)"
				:class="{selected: user.isAdmin && !isBusy}"
			>
				Admin
			</button>
		</div>
		<div class="manage-user__toggle-warning" v-if="joined">User role changes will be reflected upon refresh</div>
		<br>

		<btn @onClick="removeUser" v-if="canRemove" xlarge colour="red"> <template #text>Remove user from this Space</template></btn>
		<btn @onClick="cancelInvite" v-if="canCancelInvite" xlarge colour="ghost"> <template #text>Cancel Space invite</template></btn>
		<btn @onClick="reInvite" v-if="canReInvite" xlarge colour="green"> <template #text>Re-invite</template></btn>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserIcon from '@/components/UserIcon.vue';
import { TextInput } from '@/framework';

export default {
	components: { UserIcon, TextInput },
	props: {
		user: Object,
		resetData: Function
	},
	computed: {
		...mapGetters(['current_user/id']),
		joined: vm => vm.user.status === 'joined',
		pending: vm => vm.user.status === 'unused',
		name: vm => `${vm.user.firstName || ''} ${vm.user.lastName || ''}`.trim(),
		pronouns: vm => vm.user.pronouns ? `(${vm.user.pronouns})` : '',
		inviteURL: vm => `${location.origin}/invite?token=${vm.user.inviteToken}`,
		date: vm => !vm.joined ? '' : new Date(vm.user.usedAt).toLocaleTimeString('en-GB', {
			weekday: "short",
			month: "short",
			day: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}).replaceAll(',', ''),
		canRemove: vm => {
			return vm.joined
		},
		canCancelInvite: vm => {
			return vm.pending
		},
		canReInvite: vm => {
			return vm.user.status === 'cancelled' || vm.user.status === 'expired' || vm.user.status === 'rejected'
		}
	},
	data: () => ({
		isBusy: false,
	}),
	methods: {
		...mapActions({
			close: 'modal/close',
			genericHandler: 'api/genericHandler',
			handleInviteUsersToSpaceByEmail: 'handleInviteUsersToSpaceByEmail',
			removeUserFromSpace: 'analytics/removeUserFromSpace',
			toggleUserSpaceAdmin: 'analytics/toggleUserSpaceAdmin'
		}),
		...mapActions('routing', ['goToCurrentArea', 'goToHome']),
		...mapActions(['popups/show/error/custom']),
		async removeUser(){
			if(this.isBusy) return;

			this.isBusy = true;
			const res = await this.genericHandler([
				'nav/adminRemoveUsersFromSpace',
				[this.user.userId],
				() => this['popups/show/error/custom']('removing this User')
			])

			if(res.success){
				if(this.user.userId === this['current_user/id']) {
					this.goToHome()
				} else {
					this.resetData()
				}
				this.removeUserFromSpace(this.user)
				this.close();
			}

			this.isRemoving = false
		},
		async cancelInvite() {
			if (this.isBusy) return;
			this.isBusy = true

			const res = await this.genericHandler([
				'nav/adminCancelOfferSpaceAccess',
				this.user.id
			])

			if(res.success) {
				this.resetData()
				this.close()
			} else this['popups/show/error/custom']('cancelling the Invite')

			this.isBusy = false
		},
		async reInvite() {
			if (this.isBusy) return;
			this.isBusy = true

			const res = await this.handleInviteUsersToSpaceByEmail({
				emails: [this.user.email],
			})

			if (res) {
				this.resetData()
				this.close()
			}

			this.isBusy = false
		},
		async toggleAdmin(toggleTo){
			if(this.user.isAdmin === toggleTo || this.isBusy) return
			this.isBusy = true;

			let res = await this.genericHandler([
				'nav/adminSetUserAdmin',
				[this.user.userId, toggleTo]
			])

			this.isBusy = false

			if(res?.success){
				this.user.isAdmin = toggleTo
				this.toggleUserSpaceAdmin([this.user, toggleTo ? 'admin' : 'user'])
				if(this.user.userId === this['current_user/id'] && !toggleTo) {
					this.goToCurrentArea()
					this.close()
				}
				// this.resetData();
			} else this['popups/show/error/custom']('changing user permissions')
		}
	}
}
</script>

<style lang="scss" scoped>
.manage-user {
	display: flex;
	flex-flow: column;
	align-items: center;
	color: #B3B3B3;
	line-height: 1;
	font-weight: bold;
	text-align: center;
	gap: 25px;
	padding-bottom: 30px;

	&__inviteURL {
		width: 80%;
	}

	&__icon {
		height: 160px;
		width: 160px;
		border: 6px solid #414141 !important;
		box-sizing: border-box;
	}

	&__name {
		color: white;
		font-size: 1.5rem;
		margin-bottom: 10px;

		& .pronouns {
			font-weight: normal;
			color: $grey-3;
			font-size: 1.1rem;
		}
	}
	&__email {
		font-size: 1.25rem;
	}

	&__fields {
		display: flex;
		flex-flow: column;
		gap: 7px;
	}

	&__fields, &__date {
		font-size: 0.85rem;
	}

	&__toggleAdmin {
		margin-bottom: 10px;
		display: flex;
		width: 250px;

		> button {
			width: 50%;
			border-radius: 0;
			color: white;
			display: block;
			background: none;
			border: 2px solid rgba(255,255,255,0.5);
			font-weight: bold;
			font-size: 1.125rem;
			box-sizing: border-box;
			line-height: 1;
			padding: 12px 20px;
			transition: 0.1s;
			cursor: pointer;
			border-radius: 1000px;

			&:hover {
				border-color: white;
			}

			&.selected {
				cursor: auto;
			}

			&.user {
				border-bottom-left-radius: 10000px;
				border-top-left-radius: 10000px;
				border-right: none;

				&.selected {
					background: $user-color;
					border-color: $user-color;
				}
			}
			&.admin {
				border-bottom-right-radius: 10000px;
				border-top-right-radius: 10000px;
				border-left: none;

				&.selected {
					background: $admin-color;
					border-color: $admin-color;
				}
			}
		}
	}
	&__toggle-warning {
		margin-bottom: 20px;
		line-height: 1.3rem;
	}

	&__remove {
		$c: #FF3746;
		color: $c;

		&:hover {
			color: white;
			border-color: $c;
			background: $c;
		}
	}
}
</style>
